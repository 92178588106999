import dynamic from 'next/dynamic'
import Meta from 'components/meta/meta'
// import Image from 'components/image/image'
import styles from 'styles/page/home2.module.scss'
import Svg from 'components/svg/svg'
// import Layer from 'components/layer/layer'
import { cn } from 'utils/helpers'
import Features from 'components/features/features'
import Selections from 'components/selections/selections'
// import Eureka from 'components/eureka/eureka'

import Ahome from 'components/hits/ahome-atributika'
// import Ahome from 'components/hits/ahome'
import Acats from 'components/hits/acats'

// import { useState, useEffect } from 'react'
// import { get } from 'utils/api'

// const HomeBanner = dynamic(() => import('components/home-banner/home-banner'))
// const Velykos = dynamic(() => import('components/hits/velykos'))
// const Ukraina = dynamic(() => import('components/hits/ukraina'))
// const Kaledos = dynamic(() => import('components/hits/kaledos'))
// const Popular = dynamic(() => import('components/popular/popular'))
// const Categories = dynamic(() => import('components/hits/categories'))


const Reviews = dynamic(() => import('components/reviews/reviews'))
const Stories = dynamic(() => import('components/stories/stories'))
const HomeArticles = dynamic(() => import('components/home-articles/home-articles'))
// const Mothers = dynamic(() => import('components/hits/mothers'))
// const Builder = dynamic(() => import('components/builder/builder'))
// const Vestuves = dynamic(() => import('components/hits/vestuves'))
// const Ahome = dynamic(() => import('components/hits/ahome'))
// const Acats = dynamic(() => import('components/hits/acats'))

// <Mothers />
// {props && <Builder data={props} title="Dovanų rinkinys mamai" />}

export default function Page() {
    const other = ['Unikalios', 'Neatrastos', 'Ypatingos kokybės', 'Atsakingos', 'Kūrybiškos']
    other.sort(() => Math.random() - 0.5)
    // const strings = ['Nepàprastos', ...other]

    // const [props, setProps] = useState()
    // const init = async () => {
    //     const response = await get('women-products')
    //     if (response?.data?.data) {
    //         setProps(response.data.data)
    //     }
    // }
    // useEffect(() => init(), [])
    // <Ukraina />
    // <Kaledos />
    // <Features />
    // <Popular />
    return (
        <>
            <Meta
                title="Nepàprastos Lietuvos gamintojų prekės"
                appName=""
                description="Unikali platforma, kurioje susitinka išskirtinių prekių kūrėjai bei kokybę vertinantys pirkėjai. Mums svarbu autentiškumas ir lokalumas, todėl renkamės tik lietuviškus produktus."
            />
            <div className={cn(styles.rrr, 'mb48')}>
                <div className={styles.ttt}>
                    <div className={styles.bbb}></div>
                    <div className={styles.sss}>
                        <Selections />
                    </div>
                    
                    <Svg className={styles.bottom} name="paper-bottom" />
                </div>
            </div>

            
            
            <Ahome/>

            <Reviews/>
           
            {/* <div className={styles.topw}>
                <Svg className={styles.top} name="paper-top" />
                <Layer className={cn(styles.hero, 'mb48')}>
                    
                    <HomeBanner />
                    <div className={styles.bg}>
                        <div className={styles.image} style={{ backgroundImage: 'url(/img/merged.jpg)' }} />
                        <div className={styles.mobileImage} style={{ backgroundImage: 'url(/img/merged-mobile.jpg)' }} />
                        <Svg name="paper-top" className={styles.paper1} />
                        <Svg name="paper-top" className={styles.paper2} />
                    </div>
                    <div className={styles.content}></div>
                </Layer>
            </div> */}
            <Acats />
            <Stories />
            <Features />
            {/* <Eureka/> */}
            <HomeArticles />
        </>
    )
}
// <Plan />

// <Categories insert={Stories} />
// <div className={styles.hero}>
//     <Image
//         src="/img/about-1.jpg"
//         alt="Lietuvos smulkiųjų gamintojų prekės"
//         priority={true}
//         layout="fill"
//         objectFit="cover"
//     />
//     <div className={styles.heroText}>
//         <Heading tag="h1" size="48" className="mb24">
//             <b>Nepàprastos</b> <span>Lietuvos gamintojų prekės</span>
//         </Heading>
//         <p>
//             Atraskite unikaliausius Lietuvos gamintojus ir amatininkus vienoje vietoje, neišeidami iš namų.
//             Puoselėkime savo kultūrą ir remkime smulkųjį verslą kartu.
//         </p>
//     </div>
// </div>

//
// <ShipTime />
// <div>
//     <ImageText image="/img/about-3.jpg" url="/apie" name="Autentiškumas ir kokybė">
//         <Heading tag="h2" size="36" className="mb24">
//             <Link href={getRoute('about')}>Autentiškumas ir kokybė</Link>
//         </Heading>
//         <p>
//             Lietuviškaprekė.lt – tai internetinė prekyvietė, kurioje galite rasti unikaliausias, plataus
//             profilio Lietuvos gamintojų prekes.
//         </p>
//         <p>
//             Mūsų tikslas yra išsaugoti ir skatinti Lietuvos smulkiųjų verslininkų, menininkų ir amatininkų
//             kūrybiškumą bei suteikti jiems visas galimybes dalintis savo darbais. Įkvėpta žmonių ir jų
//             kūrybiškumo, Lietuviškaprekė.lt padeda kokybiškiausiems rankų darbo dirbiniams atrasti
//             tinkamiausius namus.
//         </p>
//         <p>
//             <Link href={getRoute('about')}>Plačiau</Link>
//         </p>
//     </ImageText>
// </div>

// export async function getStaticProps() {
//     return await getData('home-accounts')
// }

/*
const Personalization = dynamic(() => import('components/personalization/personalization'))
<div className={styles.personalization}>
    <Personalization home />
</div>
*/

// import Link from 'next/link'
// import ShipTime from 'components/ship-time/ship-time'
//import Logos from 'components/logos/logos'
// import { getRoute } from 'utils/helpers'
// import { getData } from 'utils/api'
// const Logos = dynamic(() => import('components/logos/logos'))
// cosnst ImageText = dynamic(() => import('components/image-text/image-text'))
